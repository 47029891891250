<template>
  <i
    class="icon"
    :class="[
      `icon-${$props.icon}`,
    ]"
  />
</template>

<script>
/**
 * @group Base
 * Functional component
 * Renders an icon
 */
export default {
  props: {
    // Sets the icon colour
    color: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.icon {
  position: relative;
  top: 1px;
}

.icon + * {
  margin-left: 10px;
}
</style>
